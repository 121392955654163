import type { Movie } from '~/entities/models/moive';
import type { Keyword } from '~/entities/models/slug-item/keyword';

export function mapMovieItems<T>(movies: Movie[]) {
  return movies.map(
    (item) =>
      ({
        ...item,
        Name: item.Name,
        OriginalName: item.OriginalName,
        Rating: item.Rating,
        Quality: item.Quality,
        Thumbnail: item.Thumbnail,
        URL: `/phim/${item.Slug}`,
        Keywords: [
          ...(item.Keywords ?? []),
          { Name: item.Name, Slug: generateUrlFromText(item.Name) },
        ] as Keyword[],
      } as T)
  );
}

export function handleMovieProps<T>(movieSource: Movie) {
  let movie = {
    ...movieSource,
    Name: movieSource.Name,
    OriginalName: movieSource.OriginalName,
    Rating: movieSource.Rating,
    Quality: movieSource.Quality,
    Thumbnail: movieSource.Thumbnail,
    URL: `/phim/${movieSource.Slug}`,
    Keywords: [
      ...(movieSource.Keywords ?? []),
      { Name: movieSource.Name, Slug: generateUrlFromText(movieSource.Name) },
    ] as Keyword[],
  } as T;
  return movie;
}
