import cloneDeep from 'lodash/cloneDeep';
import { isBaseItemEntity } from '~/entities/models/base-entity';
import type { NavbarItem } from '~/entities/ui/header/navbar-item';
import { UseStateKey } from '~/enums/use-state-enum';

const vietnameseToAsciiMap = {
  á: 'a',
  à: 'a',
  ả: 'a',
  ã: 'a',
  ạ: 'a',
  ă: 'a',
  ắ: 'a',
  ằ: 'a',
  ẳ: 'a',
  ẵ: 'a',
  ặ: 'a',
  â: 'a',
  ấ: 'a',
  ầ: 'a',
  ẩ: 'a',
  ẫ: 'a',
  ậ: 'a',
  đ: 'd',
  é: 'e',
  è: 'e',
  ẻ: 'e',
  ẽ: 'e',
  ẹ: 'e',
  ê: 'e',
  ế: 'e',
  ề: 'e',
  ể: 'e',
  ễ: 'e',
  ệ: 'e',
  í: 'i',
  ì: 'i',
  ỉ: 'i',
  ĩ: 'i',
  ị: 'i',
  ó: 'o',
  ò: 'o',
  ỏ: 'o',
  õ: 'o',
  ọ: 'o',
  ô: 'o',
  ố: 'o',
  ồ: 'o',
  ổ: 'o',
  ỗ: 'o',
  ộ: 'o',
  ơ: 'o',
  ớ: 'o',
  ờ: 'o',
  ở: 'o',
  ỡ: 'o',
  ợ: 'o',
  ú: 'u',
  ù: 'u',
  ủ: 'u',
  ũ: 'u',
  ụ: 'u',
  ư: 'u',
  ứ: 'u',
  ừ: 'u',
  ử: 'u',
  ữ: 'u',
  ự: 'u',
  ý: 'y',
  ỳ: 'y',
  ỷ: 'y',
  ỹ: 'y',
  ỵ: 'y',

  // Add more mappings as needed
} as any;
export function generateUrlFromText(text: string) {
  // Mapping of Vietnamese characters to ASCII equivalents

  // Convert text to lowercase
  let url = text.toLowerCase();

  // Replace Vietnamese characters with their ASCII equivalents
  url = url.replace(
    /[áàảãạăắằẳẵặâấầẩẫậđéèẻẽẹêếềểễệíìỉĩịóòỏõọôốồổỗộơớờởỡợúùủũụưứừửữựýỳỷỹỵ]/g,
    (char) => vietnameseToAsciiMap[char] || char
  );

  // Replace spaces with hyphens
  url = url.replace(/\s+/g, '-');

  // Remove non-alphanumeric characters except hyphens
  url = url.replace(/[^a-z0-9-]/g, '');

  // Prepend a forward slash
  url = '/' + url;

  return url;
}

export function getDisplayItem(item: any): string {
  if (isBaseItemEntity(item)) {
    return item.Name ?? '';
  } else {
    return item ?? '';
  }
}

export function scrollToTarget(target: string) {
  $(target).fadeIn('slow');
  $('html, body').animate(
    {
      scrollTop: $(target)?.offset()?.top,
    },
    500
  );
}

export function getHeaderGroupName(slug: string) {
  switch (slug) {
    case 'phim-bo-moi-cap-nhat':
      return 'bộ mới cập nhật';
    case 'phim-chieu-rap':
      return 'Chiếu rạp';
    case 'phim-le-moi-cap-nhat':
      return 'lẻ mới cập nhật';
    case 'phim-le':
      return 'lẻ';
    case 'phim-bo':
      return 'bộ';
    case 'dien-vien':
      return 'diễn viên';
  }

  const groups = useState<NavbarItem[]>(UseStateKey.NavbarItems);
  let header = null;
  if (groups.value?.length) {
    const source = [];
    for (let group of groups.value) {
      source.push(group);
      for (let child of group?.Children ?? []) {
        source.push(child);
      }
    }

    header =
      source.find((item) => item.To?.includes(slug))?.Label ??
      slug.replace('/', '');
  }
  return header;
}

export function getNavbarItemsByGroupName(name: string) {
  const groups = useState<NavbarItem[]>(UseStateKey.NavbarItems);
  const group = cloneDeep(
    groups.value?.find((item) => item.Label?.includes(name))
  )?.Children?.map((child) => child);
  return group ?? [];
}

export interface Base64ParseResult {
  value: string | null;
  isSuccessful: boolean;
}

export function tryParseBase64(base64: string) {
  const result = {
    value: null,
    isSuccessful: false,
  } as Base64ParseResult;
  try {
    if (typeof base64 !== 'string') {
      return theResultIs(false);
    }
    // Check for Base64 pattern
    const base64Pattern =
      /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
    if (!base64Pattern.test(base64)) {
      return theResultIs(false);
    }
    // Attempt to decode
    let value = atob(base64);
    return theResultIs(true, value);
  } catch (err) {
    return theResultIs(false);
  }

  function theResultIs(isSuccessful: boolean, value: string | null = null) {
    result.isSuccessful = isSuccessful;
    result.value = value;
    return result;
  }
}

export function getPagingResponse<T>(response: any) {
  let pagingResponse = response as T;
  if (response.Data) {
    pagingResponse = response.Data;
  }
  return pagingResponse;
}
